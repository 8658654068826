<template>
  <div class="page">
      <h1>Sign in or register</h1>
          <form @submit.prevent="" v-on:submit="validate()">
          <div class="line">
            <label name="label_nom" for="name">E-mail address</label>
            <input type="text" id="email" size="50" name="user_name" placeholder="email" required>
          </div>
          <div class="line">
            <label >Password</label>
            <input type="password" id="password" name="user_password" placeholder="password" required>
          </div>
            <a href="#" onclick="return false;" v-on:click="goToForgotPassword()">Forgot your password ?</a>
            <br>
            
          <div class="boutons">
            <button class="signIn mb-2 mb-md-0" type="submit">Sign in</button>
            <button class="register" type="button" v-on:click="goToCreerCompte()">Register</button>
          </div>
      </form>
  </div>
</template>

<script>
const axios = require('axios');
import router from '../router'
export default {
  name: 'Connexion',
  data: () => ({
  }),
  methods: {
    async validate () {
      var self = this
      
      var headers = {
          'Content-Type': 'application/json'
      }
      var data = {
        email: document.getElementById("email").value,
        password: document.getElementById("password").value
      }
      await axios.post('https://api.fkv-vss.com/api/Connect', data, {headers:headers}).then(function (response) {
        if (response.data.message === 'You are connected !') {
          if (response.data.status != "activated" && response.data.status != "admin") {
            alert("Your account hasn't been activated by an admin. Please retry later when it is activated.")
          }
          else if (response.data.status == "activated" || response.data.status == "admin") {
            if(self.$session.exists()) self.$session.destroy()
            self.$session.start()
            self.$session.set('username',response.data.username)
            self.$session.set('email',response.data.email)
            router.push('/Menu').catch(() => {})
          }
          else {
            alert("Unknown error")
          }
        }
        else {
          alert(response.data.message)
        }
      })
    },
    goToCreerCompte: function () {
        router.push('/CreerCompte').catch(() => {})
    },
    goToForgotPassword: function () {
        router.push('/ForgotPassword').catch(() => {})
    },
  }
}
</script>

  <style scoped>
  .signIn {
    float: left;
    border-radius: 5px;
    margin-top: 5%;
  }
  .register {
    float: right;
    margin-top: 5%;
  }
.line {
  padding-bottom: 5px;
}
a {
  float: right;
}
label {
  display: block;
  text-align: left;
}
h1 {
  overflow-wrap: normal;
}
.page {
  width: 50%;
  margin-left: 25%;
}

@media only screen and (max-width: 912px) {
  .page {
    width: 100%;
    margin-left: 0%;
  }
  button {
    height: 100%;
    padding-top: 3%;
    padding-bottom: 3%;
  }
}
@media only screen and (max-width: 438px) {
  .signIn {
    float: unset;
  }
  .register {
    float: unset;
  }
  a {
    float: unset;
  }
}
input {
  width: 100%;
}
</style>